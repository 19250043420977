@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;


}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


.reg-login{
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 17px;
}
.reg-login:hover{
  background-color: #dcdcdc59; 
  font-size: 18px;
}

ul{
    list-style: none;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #DCDCDC;
  border-radius: 50px;
}


.container {
  padding: 0 2rem;
}

.main {
  min-height: 100vh;
  padding: 4rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.title a {
  color: #0070f3;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: center;
}

.description {
  margin: 4rem 0;
  line-height: 1.5;
  font-size: 1.5rem;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
}
.notify{
  position: absolute;
  margin-top: -20px;
  margin-left: -20px;
  /* padding: 5px; */
  color: red;
}
.hide{
  display: none;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 300px;
}

.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}
.privacy-cotainer{
  padding-top: 140px;
  padding-bottom: 190px;
  width: 60%;
  margin: auto;
  background-color: white;
}
.intro{
  list-style-type: decimal;
  font-size: 20px;
}
.intro p,.sub-info {
  font-size: 18px;
}
.intro h3{
  padding-top: 60px;
}
.by-using{
  font-weight: 400;
}
.prv-header{
  font-family:  Roboto;
  margin-left: 23px;
  padding-bottom: 80px;
  text-align: center;
  color: #404863;
}
.sub-info li{
  list-style-type:disc;
  padding-bottom: 25px;
  font-size: 18px;
  padding-left: 0;
}
.in-general{
  list-style: disc;
  font-size: 18px;
}
#confirm-privacy .checkbox-data{
  border-color: red;
  /* border: 1px solid red; */

}
.your-rights{
  list-style-type: disc;
  font-size: 18px;
}
.contact-us a{
  color:#0070f3;
text-decoration: underline;
}

.accept-decline-privacy{
  display: flex;
  justify-content: center;
  align-items: center;
}
.accept-decline-privacy button{
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}
.accept-Terms{
  margin-right: 20px;
  background-color:#c7ddf6;
  color: #6376db;
}
.decline-terms{
  background-color: rgb(250, 179, 179);
  color: red;
}
@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
  .privacy-cotainer{
    width: 100%;
  }
}
