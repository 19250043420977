.paymentRQ-home{

    position: absolute;
    z-index: 1000;
    /* top: 100px; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.paymentRQ-modal{
    width: 30%;
}